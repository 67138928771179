import { 
    css, 
  } from "@bdfx/fukit"

export const clip = "polygon(0% 0, 100% 0%, 100% calc(100% - 7vw), 50% 100%, 0 calc(100% - 7vw))"
export const shadow = "drop-shadow(0px 8px 4px rgba(0,0,0,0.2))"


const Section = css("div")({
    filter: shadow,
    position: "relative",
    textAlign: "left",
    "> div": {
      clipPath: clip,
      background: "#fff",
      marginTop: "-300px",
      paddingTop: "350px",
      paddingBottom: "120px",
      "> div": {
        maxWidth: "1200px",
        margin: "auto",
        },
    },
  
  })

  export default Section