import React, {useState} from 'react';
import pattern from '../../resources/pattern.png';
import infografika from '../../resources/infografika.webp';
import { 
  css, 
  modal,
  Grid,
} from "@bdfx/fukit"
import Form from "../../common/homepage/form"
import References from "./references"
import Showroom from "./showroom"
import ctrans from "../../common/trans"
import {shadow, clip} from "./section"
import {eventGA} from "../../common/tracking"

export function findGetParameter(parameterName) {
  var result = null ,tmp = [];
  window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
  return result ? decodeURIComponent(result) : null;
}

const Lazyload = ({ loader }) => {
  const Component = React.lazy(loader)
  return (props) => <React.Suspense fallback={() => <div/>} >
                      <Component {...props} />
                  </React.Suspense>
}

const Modal = Lazyload({
  loader: () => import(/* webpackChunkName: "modal" */ './modal'),
});

function Homepage() {
  const [feed_url, setFeedUrl]:[string, Function] = useState(findGetParameter('feed') ?? "")
  const [conversion_url, setConversionUrl]:[string, Function] = useState(findGetParameter('conversion') ?? "")
  const [element, setElement]:[string, Function] = useState(findGetParameter('element') ?? "")

  return (
    <>  
        <HeroSection>
          <div>
          <HeroContent cols="1fr auto" gap="50px" valign="center">
            <HeroText>
              {ctrans("pricing_audit_hero_text")}
            </HeroText>
              <img width="539" height="147" src={infografika} alt="Infografika" />
          </HeroContent>
          </div>
        </HeroSection>
        <FormSection>
          <div>
            <Form feed_url={feed_url} setFeedUrl={setFeedUrl} conversion_url={conversion_url} setConversionUrl={setConversionUrl}
                  element={element} setElement={setElement}
                  showModal={() => {
                    eventGA("AUDIT", "Open modal window", "OPEN_MODAL")

                    modal({closeButton: undefined, closeOnClickOutside: false, message: (close) => <Modal close={close} 
                                                                                            feed_url={feed_url}
                                                                                            conversion_url={conversion_url}
                                                                                            element={element}
                                                                                      />})
                    }
                  }
            />
          </div>
        </FormSection>
        <ReferencesSection>
          <div>
            <References />
          </div>
        </ReferencesSection>
        <ShowroomSection>
          <div>
            <Showroom />
          </div>
        </ShowroomSection>
    </>
  );
}

const HeroSection = css("div")({
  filter: shadow,
  position: "relative",
  zIndex: 4,
  "> div": {
    clipPath: clip,
    background: "#3C3C3C",
    backgroundImage: "url("+pattern+")",
  },
})

const FormSection = css("div")({
  position: "relative",
  zIndex: 3,
  filter: shadow,
  "> div": {
    clipPath: clip,
    background: "#fff",
    padding: "0 1em",
    marginTop: "-100%",
    paddingTop: "100%",
  },

  "@media only screen and (max-width: 1000px)": {
    ".fuk-grid": {
      display: "block",
      textAlign: "center",
      " > div": {
        marginBottom: "4em",
      },
    },
    ".fuk-button": {
      marginTop: "-2em",
    },
  },

})


const ReferencesSection = css("div")({
  position: "relative",
  zIndex: 2,
  filter: shadow,
  "> div": {
    clipPath: clip,
    background: "#f6f6f6",
    marginTop: "-200px",
    paddingTop: "200px",
  },
  "@media only screen and (max-width: 1000px)": {
      "img": {
        width: "125px",
        height: "125px",
      },
      "p": {
        marginLeft: "-80px",
        fontSize: "1.2rem",
        padding: "1em",
      },
  },
})



const ShowroomSection = css("div")({
  position: "relative",
  zIndex: 1,
  filter: shadow,
  "> div": {
    clipPath: clip,
    background: "#e7e7e7",
    marginTop: "-300px",
    paddingTop: "300px",
    marginBottom: "-150px",
  },
  "@media only screen and (max-width: 1000px)": {
    ".fuk-grid": {
      display: "block",
      "p": {
        textAlign: "center",
        fontSize: "1.2rem",
        padding: "1em",
      }
    },
  },
})


const HeroContent = css(Grid)({
  maxWidth: "1100px",
  margin: "auto",
  padding: "4em 1em 9em",

  "@media only screen and (max-width: 1000px)": {
    display: "block",
    textAlign: "center",
    padding: "3em 1em 5em",
    "img": {
      display: "none",
    }
  }

})
const HeroText = css("h1")({
  fontSize: "50px",
  color: "white",
  textAlign: "left",
  fontWeight: "normal",
  lineHeight: "1.25em",
  ".green": {
    color: "#93C74F",
  },
  "@media only screen and (max-width: 1000px)": {
    textAlign: "center",
  },

})

export default Homepage;
