const messages = {
    "currency_cz": [
        "Kč",
        "Kč",
    ],
    "currency_sk": [
        "EUR",
        "EUR",
    ],
    "price_level": [
        "Cenová hladina",
        "Cenová hladina",
    ],
    "products_found": [
        "Nalezené produkty",
        "Nájdené produkty",
    ],
    "IN_STOCK": [
        "skladem",
        "skladom",
    ],
    "THREE_DAYS": [
            "do 3 dnů",
            "do 3 dní",
    ],
    "ONE_WEEK": [
            "do týdne",
            "do týždňa",
    ],
    "TWO_WEEKS": [
        "do 2 týdnů",
        "do 2 týždňov",
    ],
    "MONTH": [
        "do měsíce",
        "do mesiaca",
    ],
    "MORE_THAN_MONTH": [
        "více než měsíc",
        "viac ako mesiac",
    ],
    "UNKNOWN": [
        "info v obchodu",
        "info v obchode",
    ],

    "audit_result": [
        "Výsledky auditu",
        "Výsledky auditu",
    ],
    "report_generated": [
        "Report vygenerován",
        "Report vygenerovaný",
    ],
    "for": [
        "pro",
        "pro",
    ],
    "print": [
        "Tisk",
        "Tlač",
    ],
    "back": [
        "Zpět",
        "Späť",
    ],
    "export": [
        "Export",
        "Export",
    ],
    "n_products": [
        { "count": [
            { 'if': "{n} == 1", 'then': "{n} produkt" },
            { 'if': "{n} > 1 && {n} <= 4", 'then': "{n} produkty" },
            { 'if': "{n} > 4 || {n} === 0", 'then': "{n} produktů" },
            { 'else': 'produktů' },
        ]
        },
        { "count": [
            { 'if': "{n} == 1", 'then': "{n} produkt" },
            { 'if': "{n} > 1 && {n} <= 4", 'then': "{n} produkty" },
            { 'if': "{n} > 4 || {n} === 0", 'then': "{n} produktov" },
            { 'else': 'produktov' },
        ]
        },
    ],

    "out_of": [
        "z celkových",
        "z celkových",
    ],
    "name": [
        "Název",
        "Názov",
    ],
    "price": [
        "Cena",
        "Cena",
    ],
    "availability": [
        "Dostupnost",
        "Dostupnosť",
    ],
    "actions": [
        "Akce",
        "Akcie",
    ],

    "product": [
        "Produkt",
        "Produkt",
    ],

    "price_histogram": [
        "Rozložení cen",
        "Rozloženie cien",
    ],

    "audit_is_in_progress": [
        "Audit se aktuálně zpracovává",
        "Audit sa aktuálne spracováva",
    ],

    "back_to_result": [
        "Zpět na výsledky",
        "Späť na výsledky",
    ],

    "detail": [
        "Detail",
        "Detail",
    ],

    "errors": [
        "Problémy",
        "Problémy",
    ],
    "warnings": [
        "Upozornění",
        "Upozornenie",
    ],
    "suggestions": [
        "Doporučení",
        "Odporúčania",
    ],
    "products_count": [
        "Počet produktů",
        "Počet produktov",
    ],
    "example": [
        "Ukázka výsledků auditu",
        "Ukážka výsledkov auditu",
    ],
    "contact": [
        "Kontakt",
        "Kontakt",
    ],
    "footer_pf_info": [
        "Automatická cenotvorba za fixní cenu? Zkuste <a href=\"https://pricing-fox.cz/\" target=\"_blank\">Pricing Fox.</a>",
        "Automatická cenotvorba za fixnú cenu? Skúste <a href=\"https://pricing-fox.sk/\" target=\"_blank\">Pricing Fox.</a>",
    ],
    "conditions": [
        "Podmínky užití",
        "Podmienky používania",
    ],
    "footer_legal_info": [
        "Provozovatelem stránek je Bidding Fox technologies, s.r.o., IČ 04677200.<br/>Bidding Fox technologies, s.r.o. člen koncernu Profitak group, s.r.o.",
        "Prevádzkovateľom stránok je Bidding Fox technologies, s.r.o., IČ 04677200.<br/>Bidding Fox technologies, s.r.o. člen koncernu Profitak group, s.r.o.",
    ],

    "pricing_audit_hero_text": [
        "<strong>Zvyšte <span class=\"green\">potenciál</span><br/>svého e-shopu<br/>na srovnávačích</strong>",
        "<strong>Zvýšte <span class=\"green\">potenciál</span><br/>svojho e-shopu<br/>na porovnávačoch</strong>",
    ],
    "email_legal_info": [
        "Vaší emailovou adresu použijeme pouze pro potřeby jednorázového zaslání výsledků. Kompletní znění <a href=\"/podminky/\" target=\"_blank\">podmínek naleznete zde</a>",
        "Vašu emailovú adresu použijeme iba pre potreby jednorazového zaslanie výsledkov. Kompletné znenie <a href=\"/podmienky/\" target=\"_blank\">podmínek naleznete zde</a>",
    ],

    "products_histogram_by_category": [
        "Rozdělení produktů dle kategorií",
        "Rozdelenie produktov podľa kategórií",
    ],

    "category_histogram_label": [
        "KATEGORIE / POZICE DLE CENY",
        "KATEGÓRIE / POZÍCIE PODĽA CENY",
    ],

    "category_position_histogram_label": [
        "KATEGORIE / POZICE NA KARTĚ",
        "KATEGÓRIE / POZÍCIE NA KARTE",
    ],

    "position_histogram": [
        "Rozložení pozic",
        "Rozloženie pozícií",
    ],

    "higher_on_card": [
        "Výš na kartě",
        "Výš na kartě",
    ],
    "lower_on_card": [
        "Níž na kartě",
        "Níž na kartě",
    ],


    "products_sum": [
        "Σ PRODUKTŮ",
        "Σ PRODUKTOV",
    ],

    "cheaper": [
        "Levnější",
        "Lacnejšie",
    ],

    "same": [
        "Stejně",
        "Rovnako",
    ],

    "more_expensive": [
        "Dražší",
        "Drahšie",
    ],

    "conversion_url_label": [
        "Zadejte URL vašeho konverzního feedu z Heureky",
        "Zadajte URL Vášho konverzného feedu z Heuréky",
    ],
    "conversion_url_hint": [
        "Jedná se o XML feed měření konverzí, který naleznete v administraci Heureky, záložce Statistiky a reporty, podstránce Měření konverzí. Vložte URL denního reportu, kterou získáte zaškrtnutím checkboxu Generovat XML reporty.",
        "Ide o XML feed merania konverzií, ktorý nájdete v administrácii Heureky, v záložke Štatistiky a reporty, na podstránke Meranie konverzií. Vložte URL denného reportu, ktorú získate vyplnením checkboxu Generovať XML reporty.",
    ],

    "feed_url_label": [
        "Zadejte adresu vašeho XML feedu",
        "Zadajte adresu vášho XML feedu",
    ],
    "feed_url_hint": [
        "Uvedení XML feedu je nepovinné. Díky němu vám však například řekneme, kolik produktů je či není spárovaných a nebo informace o prodeji pod nákupní cenou. Feed musí být ve formátu Heureka XML s volitelným přídaným elementem pro nákupní cenu.",
        "Uvedenie XML feedu je nepovinné. Vďaka nemu vám však povieme napríklad to, koľko produktov je, alebo nie je spárovaných, alebo informácie o predaji pod nákupnou cenou. Feed musí byť vo formáte Heureka XML s voliteľným pridaným elementom pre nákupnú cenu.",
    ],

    "continue": [
        "Pokračovat",
        "Pokračovať",
    ],

    "element_label": [
        "Název elementu s NÁKUPNÍ cenou",
        "Názov elementu s NÁKUPNOU cenou",
    ],
    "element_hint": [
        "Vyplněním elementu získáte informace o pricingu kolem pořizovací ceny (např. jaké nabídky se prodávají pod její hodnotou, nebo kteří konkurenti nákupní cenu podrážejí).",
        "Vyplnením elementu získate informácie o pricingu ohľadom obstarávacej ceny (napr. aké ponuky sa predávajú pod jej hodnotou, alebo ktorí konkurenti nákupnú cenu podrážajú).",
    ],

    "progress_step1_header": [
        "Už jen krůček k Vašemu auditu",
        "Už len krôčik k Vášmu auditu",
    ],

    "email_label": [
        "Zadejte e-mailovou adresu pro zaslání výsledků",
        "Zadajte e-mailovú adresu pre zaslanie výsledkov",
    ],

    "email_placeholder": [
        "Váš e-mail",
        "Váš e-mail",
    ],

    "conditions_label": [
        "Souhlasím s <a href=\"/podminky/\" target=\"_blank\">podmínkami užití</a>",
        "Súhlasím s <a href=\"/podmienky/\" target=\"_blank\">podmienkami použitia</a>",
    ],

    "newsletter_label": [
        "Chci se přihlásit k odběru newsletterů",
        "Chcem sa prihlásiť k odberu newsletterov",
    ],

    "send": [
        "Odeslat",
        "Odoslať",
    ],

    "progress_step2_header": [
        "Děkujeme",
        "Ďakujeme",
    ],

    "result_url_label": [
        "Výsledný audit naleznete na adrese",
        "Výsledný audit nájdete na adrese",
    ],

    "copy": [
        "Kopírovat",
        "Kopírovať",
    ],

    "open": [
        "Otevřít",
        "Otvoriť",
    ],

    "progress_info": [
        "Na vašem auditu usilovně pracujeme. Jakmile bude hotový, najdete na něj odkaz zde. Zašleme vám jej také do e-mailu, který jste zadali v předešlém kroku",
        "Na vašom audite už usilovne pracujeme. Keď bude hotový, nájdete tu naňho odkaz, ktorý taktiež zašleme aj na e-mail zadaný v predošlom kroku",
    ],

    "progress_steps": [
        "Zatím můžete sledovat, v jaké fázi se audit nachází",
        "Zatiaľ môžete sledovať, v akej fáze sa audit nachádza",
    ],

    "progress_step1": [
        "Stahování feedů",
        "Sťahovanie feedov",
    ],
    "progress_step2": [
        "Parsování dat",
        "Parsovanie dát",
    ],
    "progress_step3": [
        "Stahování informací z Heureky",
        "Sťahovanie informácií z Heuréky",
    ],
    "progress_step4": [
        "Sestavování výsledků",
        "Zostavovanie výsledkov",
    ],
    "progress_step5": [
        "Finalizace auditu",
        "Finalizácia auditu",
    ],

    "showroom_title": [
        "Podívejte se, co vás čeká",
        "Pozrite sa, čo vás čaká",
    ],


    "showroom_slide_1": [
        "V souhrnu výsledků auditu vám odhalíme počty produktů, spadajících do sekcí V pořádku, Problémy, Upozornění či Doporučení. Ke každé z nich je k dispozici detail s podrobnějšími informacemi. Dále na stránce naleznete grafy, které informují o počtech nabídek v kategoriích či nejčastějších konkurentech.",
        "V súhrne výsledkov auditu vám odhalíme počty produktov, spadajúcich do sekcií V poriadku, Problémy, Upozornenia či Odporúčania. Ku každej z nich je k dispozícii detail s podrobnejšími informáciami. Ďalej na stránke nájdete grafy, ktoré informujú o počte ponúk v kategóriách či najčastejších konkurentoch.",
    ],
    "showroom_slide_2": [
        "Rozdělení produktů dle kategorií poskytuje rozpis, kolik procent vašich spárovaných produktů se nachází v jednotlivých kategoriích a na kterých pozicích v řazení dle ceny. Následující graf a tabulka s názvem Nejčastější konkurenti ukazují, kdo jsou největší rivalové vašeho e-shopu včetně porovnání cen.",
        "Rozdelenie produktov podľa kategórií poskytuje rozpis, koľko percent vašich spárovaných produktov sa nachádza v jednotlivých kategóriách a na ktorých pozíciách v radení podľa ceny. Nasledujúci graf a tabuľka s názvom Najčastejší konkurenti ukazujú, kto sú najväčší rivali vášho e-shopu, vrátane cenového porovnania.",
    ],
    "showroom_slide_3": [
        "Rozkliknutím detailu sortimentu s problémem se dostanete na stránku, kde vám poskytneme čtyři podrobné detekce. Zjistíte například, který produkt má nulovou a zápornou cenou, či které produkty jsou nespárované. Nechybí ani výpis nabídek spadajících do těchto skupin, graf s rozložením cen a detail, export nebo tisk všech problémů.",
        "Rozkliknutím detailu sortimentu s problémom sa dostanete na stránku, kde vám poskytneme štyri podrobné detekcie. Zistíte napríklad, ktorý produkt má nulovú a zápornú cenu, alebo ktoré produkty sú nespárované. Nechýba ani výpis ponúk spadajúcich do týchto skupín, graf o rozložení cien a detail, export alebo tlač všetkých problémov.",
    ],
    "showroom_slide_4": [
        "Upozorněním vás audit bude varovat před možnými nepříjemnostmi. Dozvíte se například, které produkty mají dlouhou dodací dobu a nebo zda je konkurenti prodávají pod vaší nákupní cenou. Součástí stránky je výpis sortimentu obsaženého v upozorněních, či graf o rozložení cen. A u žádné z devíti detekcí nechybí detail, export nebo tisk.",
        "Upozornením vás bude audit varovať pred možnými nepríjemnosťami. Dozviete sa napríklad, ktoré produkty majú dlhú dodaciu dobu, alebo či ich konkurenti nepredávajú pod vašou nákupnou cenou. Súčasťou stránky je výpis sortimentu obsiahnutého v upozorneniach, či graf týkajúci sa rozloženia cien. U žiadnej z deviatich detekcií nechýba detail, export alebo tlač.",
    ],
    
    "showroom_slide_5": [
        "Součástí auditu jsou také doporučení, v nichž vám pomůžeme s vylepšováním sortimentu. Naleznete zde například výčet produktů mimo bidding, nebo těch nejlevnějších nabídek. Níže na stránce obsahující i rozpis produktů, vás bude graf informovat o rozložení cen. U každé z pěti detekcí máte možnost se prokliknout do detailu, vyexportovat si ji, nebo vytisknout.",
        "Súčasťou auditu sú tiež odporúčania, v ktorých vám pomôžeme s vylepšovaním sortimentu. Nájdete tu napríklad výpis produktov mimo bidding, alebo tých najlacnejších ponúk. Nižšie na stránke obsahujúcej aj rozpis produktov, vás bude graf informovať o rozložení cien. U každej z piatich detekcií máte možnosť prekliknúť sa do detailu, vyexportovať si ju, alebo vytlačiť.",
    ],
    

    "most_common_competitors": [
        "Nejčastější konkurenti",
        "Najčastejšia konkurencia",
    ],

    // products columns
    "IMAGE": [" ", " "],
    "NAME": [
        "Název",
        "Názov",
    ],
    "ITEM_ID": [
        "ITEM_ID",
        "ITEM_ID",
    ],
    "PRICE_VAT": [
        "Cena",
        "Cena",
    ],
    "AVAILABILITY_DAYS": [
        "Dostupnost",
        "Dostupnosť",
    ],
    "DELIVERY_PRICE": [
        "Cena dopravy",
        "Cena dopravy",
    ],
    "PRICE_POSITION": [
        "Pozice/cena",
        "Pozícia/cena",
    ],
    "BIDDED_POSITION": [
        "Pozice/bidding",
        "Pozícia/bidding",
    ],
    "SHOPS_COUNT": [
        "Počet e-shopů",
        "Počet e-shopov",
    ],
    "PURCHASE_PRICE": [
        "Nákupní cena",
        "Nákupná cena",
    ],

    "IS_TOP_WITH_CART": [
        "S košíkem",
        "S košíkom",
    ],

    "MIN_CPC": [
        "Min. CPC kategorie",
        "Min. CPC kategorie",
    ],

    "CLICKS": [
        "Prokliky",
        "Prokliky",
    ],

    "ORDERS": [
        "Objednávky",
        "Objednávky",
    ],

    "AVG_CPC": [
        "Průměrné CPC",
        "Průměrné CPC",
    ],

    "HEUREKA_CATEGORY_POSITION": [
        "Pozice v kategorii",
        "Pozice v kategorii",
    ],

    "product_feed_url": [
        "Adresa vašeho XML feedu",
        "Adresa vášho XML feedu",
    ],
    "heureka_conversion_xml_url": [
        "Adresa konverzního feedu",
        "Adresa konverzného feedu",
    ],
    "notify_email": [
        "E-mail",
        "E-mail",
    ],

    "conditions_required": [
        "Pro vytvoření auditu musíte souhlasit s podmínkami užití",
        "Pre vytvorenie auditu musíte súhlasiť s podmienkami použitia",
    ],
    "validation.required": [
        "toto pole je povinné",
        "toto pole je povinné",
    ],
    "validation.not_valid_conversion_xml": [
        "neplatná URL adresa",
        "neplatná URL adresa"
    ],
    "validation.email": [
        "Nesprávná emailová adresa",
        "Nesprávna emailová adresa",
    ],
    "validation.url": [
        "neplatná URL adresa",
        "neplatná URL adresa"
    ],
    "found_products": [
        "Nalezené produkty",
        "Nájdené produkty",
    ],
    "too_many_audits": [
        "Povoleno je vytvoření pouze jednoho auditu feedu za 24 hodin",
        "Povolené je vytvorenie iba jedného auditu feedu za 24 hodín",
    ],

    "unknown_error": [
        "Chyba při vytváření auditu. Zkuste to za chvíli znovu.",
        "Chyba pri vytváraní auditu. Skúste to za chvíľu znova.",
    ],

    "references": [
        "Reference",
        "Referencie",
    ],

    "checks_all_right": [
        "Kontroly v pořádku",
        "Kontroly v poriadku",
    ],

    "example_results": [
        "Ukázkové výsledky",
        "Ukážkové výsledky",
    ],

    "sortiment": [
        "Sortiment",
        "Sortiment",
    ],


    "page_title_pricing": [
        "Pricing Audit – Bezplatná kontrola vašich cen",
        "Pricing Audit –⁠ Bezplatná kontrola vašich cien",
    ],

    "meta_description_result_pricing": [
        "Odhalte pricing auditem nabídky s problémem, možná upozornění nebo doporučení. Grafy a podrobné informace vám odhalí vše potřebné.",
        "Odhaľte pricing auditom ponuky s problémom, možné upozornenia alebo odporúčania. Grafy a podrobné informácie vám odhalia všetko potrebné.",
    ],

    "og_title_result_pricing": [
        "Náhled výsledků pricing auditu",
        "Náhľad výsledkov pricing auditu",
    ],
    "og_description_result_pricing": [
        "Zatočte s problémy, nechte se varovat nebo si poradit, jak naložit s cenami vašeho sortimentu. Pricing audit vám se vším pomůže.",
        "Zatočte s problémami, nechajte sa upozorniť alebo si poradiť, ako naložiť s cenami vášho sortimentu. Pricing audit vám so všetkým pomôže.",
    ],

    "audit_not_found": [
        "404 Audit nenalezen",
        "404 Audit nenájdený",
    ],
    "audit_not_found_info": [
        "Jejda, tento audit v databázi nemáme. Zadali jste správnou adresu?",
        "Hups, tento audit v databáze nemáme. Zadali ste správnu adresu?",
    ],
    "audit_expired": [
        "403 Audit vyexpiroval",
        "403 Audit vyexpiroval",
    ],
    "audit_expired_info": [
        "Jejda, tento audit je starší než 7 dní a produktová data u něj již nejsou dostupná.",
        "Hups, tento audit je starší než 7 dní a produktová dáta u neho už nie sú dostupná.",
    ],
    "page_not_found": [
        "404 Stránka nenalezena",
        "404 Stránka nenájdená",
    ],
    "page_not_found_info": [
        "Jejda, tuto stránku v liščí noře nemáme. Zadali jste správnou adresu?",
        "Hups, túto stránku v líščej nore nemáme. Zadali ste správnu adresu?",
    ],

    "audit_is_in_progress_info": [
        "Ještě chviličku vydržte, za chvíli se tu objeví.",
        "Ešte chvíľočku vydržte, za chvíľu sa tu objaví.",
    ],

    "regenerate": [
        "Přegenerovat",
        "Pregenerovať",
    ],
    
    "refresh": [
        "Znovu načíst",
        "Znovu načítať",
    ],

    "create_new_audit": [
        "Zadat nový audit",
        "Zadať nový audit",
    ],

    "no_products_found": [
        "Nenašli jsme žádné produkty",
        "Nenašli sme žiadne produkty",
    ],

    "error_conversion_xml_404": [
        "Adresa konverzního XML z Heureky vrací kód 404. Zkontrolujte si prosím, zda je konverzní feed již vygenerovaný, nebo obsahuje nějaká data. Popřípadě kontaktujte podporu Heureky.",
        "Adresa konverzného XML z Heureky vracia kód 404. Skontrolujte si prosím, či je konverzný feed už vygenerovaný, alebo obsahuje nejaké dáta. Poprípade kontaktujte podporu Heureky.",
    ],
    "error_feed_404": [
        "Zadaný produktový XML feed nelze stáhnout (chyba 404 Nenalezen)",
        "Zadaný produktový XML feed nedá stiahnuť (chyba 404 Nenájdené)",
    ],
    "error_conversion_xml_other": [
        "Zadaný konverzní feed nelze stáhnout (neznámá chyba)",
        "Zadaný konverzný feed nedá stiahnuť (neznáma chyba)",
    ],
    "error_feed_other": [
        "Zadaný produktový XML feed nelze stáhnout (neznámá chyba)",
        "Zadaný produktový XML feed nedá stiahnuť (neznáma chyba)",
    ],
    "error_other": [
        "Chyba při vytváření auditu. Zkuste to za chvíli znovu.",
        "Chyba pri vytváraní auditu. Skúste to za chvíľu znova.",
    ],

    "error_conversion_xml_no_data": [
        "Z konverzního XML z Heureky se nepodařilo stáhnout žádná data. XML je buď prázdné, nebo neobsahuje element SHOP_ITEM_ID. Překontrolujte prosím URL adresu, případně nám napište na {{mail}}",
        "Z konverzného XML z Heuréky sa nepodarilo stiahnuť žiadne dáta. XML je buď prázdne, alebo neobsahuje element SHOP_ITEM_ID. Prekontrolujte prosím URL adresu, prípadne nám napíšte na {{mail}}",
    ],


    // TODO: BIDDING TYPO
    "about_audit": [
        "O auditu",
        "O audite",
    ],

    "im_not_in_bidding": [
        "Nejsem v biddingu",
        "Nie som v biddingu",
    ],
    
    "higher_in_bidding": [
        "Výš v biddingu",
        "Vyššie v biddingu",
    ],
    "lower_in_bidding": [
        "Níž v biddingu",
        "Nižšie v biddingu",
    ],
    
    "bidding_audit_hero_text": [
        "Pozvedněte svou inzerci na <strong>vyšší úroveň</strong>",
        "Pozdvihnite svoju inzerciu na <strong>vyššiu úroveň</strong>",
    ],
    "footer_bf_info": [
        "Automatický bidding za fixní cenu? Zkuste <a href=\"https://bidding-fox.cz/\" target=\"_blank\">Bidding Fox.</a>",
        "Automatický bidding za fixnú cenu? Vyskúšajte <a href=\"https://bidding-fox.sk/\" target=\"_blank\">Bidding Fox.</a>",
    ],

    "category_real_position_hint": [
        "V grafu je procentuální souhrn vašich nabídek v TOP 10 kategoriích Heureky na konkrétních pozicích. Pozice 1. až 4. se týká biddingu, dále se jedná o umístění produktů v řazení dle ceny.",
        "V grafe je percentuálny súhrn vašich ponúk v TOP 10 kategóriách Heuréky na konkrétnych pozíciách. Pozícia 1. až 4. sa týka Bidding, ďalej sa jedná o umiestnení produktov v radenie podľa ceny.",
    ],
    
    "bidding_showroom_slide_1": [
        "V souhrnu výsledků auditu je sortiment rozdělený do čtyř kategorií – V pořádku, S problémem, S upozorněním a S doporučením. V detailu každé z nich najdete výpis jednotlivých detekcí. Níže se nacházejí také grafy informující o rozložení pozic, počtu nabídek podle kategorií a nejčastějších konkurentech.",
        "V súhrne výsledkov auditu je sortiment rozdelený do štyroch kategórií –⁠ V poriadku, S problémom, S upozornením a S odporúčaním. V detaile každej z nich nájdete výpis jednotlivých detekcií. Nižšie sa nachádzajú tiež grafy informujúce o rozložení pozícií, počtu ponúk podľa kategórií a najčastejších konkurentoch.",
    ],
    "bidding_showroom_slide_2": [
        "Koláčové grafy člení sortiment na ten v biddingu a mimo něj, na produkty obsazující TOP pozice s košíkem či bez. Následuje rozložení pozic, produktů v kategoriích či výpis nejčastějších konkurentů a další užitečné informace.",
        "Koláčové grafy člení sortiment na ten v biddingu a mimo neho, na produkty obsazující TOP pozície s košíkom či bez. Nasleduje rozloženie pozícií, produktov v kategóriách či výpis najčastejších konkurentov a ďalšie užitočné informácie.",
    ],
    "bidding_showroom_slide_3": [
        "Detail produktů s problémem vám poskytne až osm podrobných detekcí. Odhalí vám nespárované produkty a nebo například nabídky s nulovou či žádnou hodnotou v elementu HEUREKA_CPC. Kromě mnoho dalšího nechybí ani výpis problémových produktů, grafy, exporty a nebo možnost tisku.",
        "Detail produktov s problémom vám poskytne až osem podrobných detekciou. Odhalí vám nespárované produkty a alebo napríklad ponuky s nulovou alebo žiadnou hodnotou v elemente HEUREKA_CPC. Okrem mnoho ďalšieho nechýba ani výpis problémových produktov, grafy, exporty a alebo možnosť tlače.",
    ],
    "bidding_showroom_slide_4": [
        "Díky upozorněním můžete předejít možným komplikacím s inzercí. Napoví vám například, které nabídky jsou příliš proklikávané, ale nemají žádné objednávky. Dalších 7 varování doplňuje nezbytný seznam detekovaných produktů včetně detailu, exportu či tisku a graf o rozložení cen.",
        "Vďaka upozornením môžete predísť možným komplikáciám s inzerciou. Napovie vám napríklad, ktoré ponuky sú príliš proklikávané, ale nemajú žiadne objednávky. Ďalších 7 varovanie dopĺňa nevyhnutný zoznam detekovaných produktov vrátane detailu, exportu či tlače a graf o rozložení cien.",
    ],
    
    "bidding_showroom_slide_5": [
        "Aby byl audit kompletní, nesmí chybět doporučení. Ty poslouží při vylepšování kondice sortimentu k dokonalosti. Jejich součástí jsou třeba detekce zaměřující se na produkty za příliš nízkou cenu oproti konkurenci a nebo na ty se zlomovou dostupností. Níže na stránce najdete opět kromě seznamu produktů i graf rozložení cen, možnost se prokliknout do detailu, exportu a tisku.",
        "Aby bol audit kompletný, nesmie chýbať odporúčania. Tie poslúžia pri vylepšovaní kondície sortimentu k dokonalosti. Ich súčasťou sú potrebné detekcie zameriavajúce sa na produkty za príliš nízku cenu oproti konkurencii a alebo na tie sa zlomovou dostupnosťou. Nižšie na stránke nájdete opäť okrem zoznamu produktov aj graf rozloženie cien, možnosť sa prekliknúť do detailu, exportu a tlače."
    ],
    
    "competitor_is": [
        "Konkurent je",
        "Konkurent je",
    ],
    
    "yes": [
        "Ano",
        "Áno",
    ],
    "no": [
        "Ne",
        "Nie",
    ],
    
    "CPC": [
        "CPC",
        "CPC",
    ],
    
    "in_bidding": [
        "V biddingu",
        "V biddingu",
    ],
    
    "page_title_bidding": [
        "Bidding Audit –⁠ Kontrola biddovaného sortimentu zdarma",
        "Bidding Audit –⁠ Kontrola biddovaného sortimentu zdarma",
    ],
    
    "meta_description_result_bidding": [
        "Odhalte stav biddovaných nabídek a zvyšte jejich úspěšnost a schopnost konkurence na srovnávačích.",
        "Odhaľte stav biddovaných ponúk a zvýšte ich úspešnosť a schopnosť konkurencie na porovnávačoch.",
    ],
    
    "og_title_result_bidding": [
        "Ukázkové výsledky | Bidding Audit",
        "Ukážkové výsledky | Bidding Audit",
    ],
    "og_description_result_bidding": [
        "Bidding Audit odhalí nejzávažnější problémy vašeho sortimentu, které brání úspěšnému biddingu a inzerci na srovnávačích zboží.",
        "Bidding Audit odhalí najvážnejšie problémy vášho sortimentu, ktoré bránia úspešnému biddingu a inzercii na porovnávači tovaru.",
    ],
    
    "not_paired_short": [
        "Nespárované",
        "Nespárované",
    ],
    "no_bidding": [
        "Bez biddingu",
        "Bez biddingu",
    ],
    "out_of_bidding": [
        "Mimo bidding",
        "Mimo bidding",
    ],
    
    "no_cart": [
        "Bez košíku",
        "Bez košíku",
    ],
    "with_cart": [
        "S Heureka košíkem",
        "S Heureka košíkom",
    ],
    "others": [
        "Ostatní",
        "Ostatné",
    ],
    "products_in_bidding": [
        "Produkty v biddingu",
        "Produkty v biddingu",
    ],
    "products_top_position": [
        "Produkty na TOP pozici",
        "Produkty na TOP pozícii",
    ],
    "first_position": [
        "1. pozice",
        "1. pozícia",
    ],
    "second_position": [
        "2. pozice",
        "2. pozícia",
    ],
    "third_position": [
        "3. pozice",
        "3. pozícia",
    ],
    "fourth_position": [
        "4. pozice",
        "4. pozícia",
    ],

    "cant_check": [
        "Tyto kontroly nelze provést",
        "Tieto kontroly nemožno vykonať",
    ],

    "missing_purchase_element": [
        "Chybí element s nákupní cenou",
        "Chýba element s nákupnou cenou",
    ],

    "missing_source_feed": [
        "Chybí zadaný produktový XML feed",
        "Chýba zadaný produktový XML feed",
    ],
    

    "accept_gdpr_analytics": [
        "Souhlasím se <a href=\"/gdpr/\" target=\"_blank\">zpracováním osobních údajů</a> pro analytické účely společnosti Bidding Fox technologies, s.r.o",
        "Súhlasím so <a href=\"/gdpr/\" target=\"_blank\">spracovaním osobných údajov</a> pre analytické účely spoločnosti Bidding Fox technologies, s.r.o",
    ],

    "accept_gdpr_marketing": [
        "Souhlasím se <a href=\"/gdpr/\" target=\"_blank\">zpracováním osobních údajů</a> pro marketingové účely společnosti Bidding Fox technologies, s.r.o",
        "Súhlasím so <a href=\"/gdpr/\" target=\"_blank\">spracovaním osobných údajov</a> pre marketingové účely spoločnosti Bidding Fox technologies, s.r.o",
    ],

    "other_audits_links_pricing": [
        "Chcete kompletně zkontrolovat stav vašeho sortimentu?<br/>Vyzkoušejte také audit "
            + "<a href=\"https://bidding-audit.cz\" target=\"_blank\"><img src=\"https://cdn.bdfx.cz/muk/icons/biddingfox.svg\" width=\"12\" height=\"12\" /> biddingu</a>, "
            + "<a href=\"https://audit.mergado.com\" target=\"_blank\"><img src=\"https://cdn.bdfx.cz/muk/icons/mergado.svg\" width=\"12\" height=\"12\" /> feedu</a> a " 
            + "<a href=\"https://audit-obrazku.cz/?utm_source=pricing-audit.cz&utm_medium=referral&utm_campaign=audit_on_web&utm_term=pricingaudit\" target=\"_blank\"><img src=\"https://cdn.bdfx.cz/muk/icons/feedimageeditor.svg\" width=\"12\" height=\"12\" /> obrázků</a>.",
        "Chcete kompletne skontrolovať stav vášho sortimentu?<br/>Vyskúšajte tiež audit "
            + "<a href=\"https://bidding-audit.sk\" target=\"_blank\"><img src=\"https://cdn.bdfx.cz/muk/icons/biddingfox.svg\" width=\"12\" height=\"12\" /> biddingu</a>, "
            + "<a href=\"https://audit.mergado.com\" target=\"_blank\"><img src=\"https://cdn.bdfx.cz/muk/icons/mergado.svg\" width=\"12\" height=\"12\" /> feedu</a> a " 
            + "<a href=\"https://audit-obrazkov.sk/?utm_source=pricing-audit.sk&utm_medium=referral&utm_campaign=audit_on_web&utm_term=pricingaudit\" target=\"_blank\"><img src=\"https://cdn.bdfx.cz/muk/icons/feedimageeditor.svg\" width=\"12\" height=\"12\" /> obrázkov</a>.",
    ],
    "other_audits_links_bidding": [
        "Chcete kompletně zkontrolovat stav vašeho sortimentu?<br/>Vyzkoušejte také audit "
            + "<a href=\"https://pricing-audit.cz\" target=\"_blank\"><img src=\"https://cdn.bdfx.cz/muk/icons/pricingfox.svg\" width=\"12\" height=\"12\" /> pricingu</a>, "
            + "<a href=\"https://audit.mergado.com\" target=\"_blank\"><img src=\"https://cdn.bdfx.cz/muk/icons/mergado.svg\" width=\"12\" height=\"12\" /> feedu</a> a " 
            + "<a href=\"https://audit-obrazku.cz/?utm_source=bidding-audit.cz&utm_medium=referral&utm_campaign=audit_on_web&utm_term=biddingaudit\" target=\"_blank\"><img src=\"https://cdn.bdfx.cz/muk/icons/feedimageeditor.svg\" width=\"12\" height=\"12\" /> obrázků</a>.",
        "Chcete kompletne skontrolovať stav vášho sortimentu?<br/>Vyskúšajte tiež audit "
            + "<a href=\"https://pricing-audit.sk\" target=\"_blank\"><img src=\"https://cdn.bdfx.cz/muk/icons/pricingfox.svg\" width=\"12\" height=\"12\" /> pricingu</a>, "
            + "<a href=\"https://audit.mergado.com\" target=\"_blank\"><img src=\"https://cdn.bdfx.cz/muk/icons/mergado.svg\" width=\"12\" height=\"12\" /> feedu</a> a " 
            + "<a href=\"https://audit-obrazkov.sk/?utm_source=bidding-audit.sk&utm_medium=referral&utm_campaign=audit_on_web&utm_term=biddingaudit\" target=\"_blank\"><img src=\"https://cdn.bdfx.cz/muk/icons/feedimageeditor.svg\" width=\"12\" height=\"12\" /> obrázkov</a>.",
    ],

}

module.exports.phrases = {
    locale: ["cs-CZ","sk-SK"],
    messages,
}
