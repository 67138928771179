import {
    theme,
} from "@bdfx/fukit"


export const ERRORS = ["not_paired", "zero_price", "no_shipping_price",  "single_shop", ]
export const WARNINGS = ["is_most_expansive","competitors_below_purchase_price", "more_than_10_position",  "p130_of_cheapest",  "competitors_with_same_price_with_free_shipping", "competitors_with_same_price_with_gift",  "few_shops", "similar_but_better_availability", "my_price_below_purchase_price"]
export const SUGGESTIONS = ["not_in_bidding", "most_expensive_in_bidding", "cheapest_in_bidding", "first_and_too_cheap", "cheapest" ]


export const getColor = (index) => {
    if(ERRORS.indexOf(index) > -1 || index === "group_problems") {
        return theme.red_raw
    }
    if(WARNINGS.indexOf(index) > -1 || index === "group_warnings") {
        return theme.orange_raw
    }
    if(SUGGESTIONS.indexOf(index) > -1 || index === "group_recommendations") {
        return theme.steelblue_raw
    }
    return theme.green_raw
}

export const getAuditUrl = (auditId) => {
    if(auditId === "example") {
        return `/ukazka_auditu/`
    }
    return `/audit/${auditId}/`
}


export function getFilters() {
    const reg = window.location?.pathname && window.location.pathname.indexOf("ukazka_auditu") > -1 ?  /\/ukazka_auditu\/([0-9a-z_]+)/g : /\/audit\/[0-9a-z]+\/([0-9a-z_]+)/g
    const pid = reg.exec(window.location.pathname)
    if (pid) {
        return trans_url_to_problem(pid[1])
    }
    return ""
}

export const getProblemUrl = (auditId, index, requested = null) => {
    return  getAuditUrl(auditId) + trans_problem_to_url(index, requested) + "/"
}

export const trans_problem_to_url = (index, requested = null) => {
    if(index === "regenerate") return "regenerate"
    const lang = (requested ? requested : process.env.LANG) === "sk" ? 1 : 0
    return trans_problems[index][lang]
}

export const trans_url_to_problem = (problem, requested = null) => {
    if(problem === "regenerate") return "regenerate"
    const lang = (requested ? requested : process.env.LANG) === "sk" ? 1 : 0
    const values = Object.keys(trans_problems).filter(index => trans_problems[index][lang] === problem)
    if(values && values.length > 0) {
        return values[0]
    }
    return ''
}

const trans_problems = {
    "group_ok": [
        "v_poradku",
        "v_poriadku",
    ],
    "group_problems": [
        "s_problemem",
        "s_problemom",
    ],
    "group_warnings": [
        "s_upozornenim",
        "s_upozornenim",
    ],
    "group_recommendations": [
        "s_doporucenim",
        "s_odporucanim",
    ],
    "not_paired": [
        "nesparovane_produkty",
        "nesparovane_produkty",
    ],
    "single_shop": [
        "sam_na_produktove_karte",
        "sam_na_produktovej_karte",
    ],
    "no_shipping_price": [
        "nemam_nastavenou_cenu_dopravy",
        "nemam_nastavenu_cenu_dopravy",
    ],
    "zero_price": [
        "s_nulovou_cenou",
        "s_nulovou_cenou",
    ],
    "competitors_with_same_price_with_free_shipping": [
        "konkurent_s_dorucenim_zdarma",
        "konkurent_s_dorucenim_zdarma",
    ],
    "competitors_with_same_price_with_gift": [
        "konkurent_s_darkem",
        "konkurent_s_darcekom",
    ],
    "more_than_10_position": [
        "na_nizke_pozici",
        "na_nizkej_pozicii",
    ],
    "is_most_expansive": [
        "nejdrazsi_produkt",
        "najdrahsi_produkt",
    ],
    "similar_but_better_availability": [
        "dlouha_doba_dodani",
        "dlha_doba_dodania",
    ],
    "p130_of_cheapest": [
        "drahe_oproti_konkurenci",
        "drahe_oproti_konkurencii",
    ],
    "competitors_below_purchase_price": [
        "konkurence_pod_nakupni_cenou",
        "konkurencia_pod_nakupnou_cenou",
    ],
    "few_shops": [
        "malo_nabidek",
        "malo_ponuk",
    ],
    "my_price_below_purchase_price": [
        "pod_nakupni_cenou",
        "pod_nakupnou_cenou",
    ],
    "not_in_bidding": [
        "mimo_bidding",
        "mimo_bidding",
    ],
    "most_expensive_in_bidding": [
        "nejdrazsi_v_biddingu",
        "najdrahsi_v_biddingu",
    ],
    "cheapest_in_bidding": [
        "nejlevnejsi_v_biddingu",
        "najlacnejsi_v_biddingu",
    ],
    "first_and_too_cheap": [
        "moc_levne",
        "prilis_lacne",
    ],
    "cheapest": [
        "nejlevnejsi",
        "najlacnejsi",
    ],

    }