import React from 'react';
import vlado from '../../resources/vlado.jpg';
import petra from '../../resources/petra.jpg';
import jirka from '../../resources/jiri-drazny.jpg';
import marek from '../../resources/marek-vogl.jpg';
import ondra from '../../resources/ondrej-vodehnal.jpg';
import ctrans from "../../common/trans"
import { 
  css, 
  Carousel,
  Grid,
  Header,
  Div,
} from "@bdfx/fukit"
function References() {
  return (
    <Style>
        <Header type={2}>{ctrans("references")}</Header>
        <Carousel count={5}>
            {(next, prev) => [
                <Div key="mikulaskova" maxWidth="1000px" margin="auto">
                <Grid cols="200px auto" gap="40px" valign="center">
                  <Div>
                    <img width="200" height="200" src={petra} alt="Petra Mikulášková doporučuje" />
                  </Div>
                  <p>
                    Audit cen oceňuji nejen já, ale hlavně ti klienti, kteří se o Heureku aktivně zajímají. Dává jim ucelený přehled o cenotvorbě napříč jejich portfoliem v porovnání s konkurencí. I když tato data jde získat i jinými způsoby, na Pricing auditu oceňuji jednoduché a pochopitelné zpracování získaných dat. A to zkrátka potřebují hlavně klienti - majitelé e-shopů.
                    <br/>
                    <br/>
                    Petra Sedláková Mikulášková, <a href="https://www.petramikulaskova.cz" rel="noreferrer" target="_blank">www.petramikulaskova.cz</a>
                  </p>
                </Grid>
            </Div>,

              <Div key="trevisan" maxWidth="1000px" margin="auto">
                  <Grid cols="200px auto" gap="40px" valign="center">
                    <Div>
                      <img width="200" height="200" src={vlado} alt="Vlado Trevisan doporučuje" />
                    </Div>
                    <p>
                      Potřeboval jsem zjistit, jakou pricingovou strategii zvolit a na které produkty se při přeceňování zaměřit. Veškeré informace mi poskytl pricing audit a následně díky aplikaci Pricing Fox jsem ušetřil spoustu času, který bych trávil manuálními úpravami. Propracované podmínky pravidel a omezení limity nebo nákupkou dělají automatickou cenotvorbu zase o něco snadnější a flexibilnější.
                      <br/>
                      <br/>
                      Vladimír Trevisan, agentura <a href="https://www.besteto.cz" rel="noreferrer" target="_blank">Besteto</a>
                    </p>
                  </Grid>
              </Div>,
              <Div key="vodehnal" maxWidth="1000px" margin="auto">
                  <Grid cols="200px auto" gap="40px" valign="center">
                    <Div>
                      <img width="200" height="200" src={ondra} alt="Ondřej Vodehnal" />
                    </Div>
                      <p>
                      Dostat produktové feedy do Heureky a Zboží je často nadlidský úkon. Nejasné chybové hlášky, změny pravidel atd. jsou pro mnoho eshoperů nepřekonatelnou překážkou. Ve feedu o tisících řádcích může občas i malá chyba způsobit vypnutí inzerce či razantní snížení její efektivity. Ale to pro naše klienty neplatí. Využíváním auditů od BF odhalujeme i sebemenší chybičky a díky radám u reportu chyb opravujeme "potížisty" rychle a, efektivně. Díky tomu naši klienti těží z Heureky či Zboží maximum.
                      <br/>
                      <br/>
                      Za team <a href="https://www.filipesmedia.cz/" rel="noreferrer" target="_blank">FILIPES MEDIA s.r.o.</a> CTO Ondřej Vodehnal 
                    </p>
                  </Grid>
              </Div>,
              <Div key="vogl" maxWidth="1000px" margin="auto">
                  <Grid cols="200px auto" gap="40px" valign="center">
                    <Div>
                      <img width="200" height="200" src={marek} alt="Marek Vogl doporučuje" />
                    </Div>
                    <p>
                      Tento nástroj ocení především takové e-shopy, které od dodavatelů importují produkty hromadně a potřebují srovnat ceny s trhem. Vhodné je také využití nástroje pro udržení minimální marže.
                      <br/>
                      <br/>
                      <a href="https://marekvogl.cz/" rel="noreferrer" target="_blank">Marek Vogl</a>, online marketingový konzultant a specialista na e-commerce
                    </p>
                  </Grid>
              </Div>,
              <Div key="drazny" maxWidth="1000px" margin="auto">
                  <Grid cols="200px auto" gap="40px" valign="center">
                    <Div>
                      <img width="200" height="200" src={jirka} alt="Jiří Drážný doporučuje" />
                    </Div>
                    <p>
                      Pricing Fox mi usnadňuje mít cenotvorbu u svých klientů pod kontrolou. Díky chytrým pravidlům a napojení na Mergado se daří komfortně uhlídat pozice každého produktu. Navíc za super cenu za tento nástroj.
                      <br/>
                      <br/>
                      Jiří Drážný, marketingový konzultant na volné noze
                    </p>
                  </Grid>
              </Div>,
              // <Div key="drazny" maxWidth="1000px" margin="auto">
              // <Grid cols="200px auto" gap="40px" valign="center">
              //   <Div>
              //     <img width="200" height="200" src={jirka} alt="Jozef Peklanský doporučuje" />
              //   </Div>
              //     <p>
              //     Už dlho som hľadal na trhu nástroj, vďaka ktorému viem pri veľkom množstve produktov držať krok čo sa cenovej politiky týka s konkurenciou.
              //     Vďaka Pricing Foxu už nemusím strácať čas porovnávaním cien veľkého množstva produktov a pravidelne kontrolovať ceny s konkurenciou, jednoduchou filtráciou produktov a nastavením rôznych požadovaných vlastností viem produkt cenovo držat na požadovanej pozícií v porovnávaní, biddingu či oproti konkurencii.
              //     Pricing Fox mi veľmi ušetril čas, vďaka čomu sa môžem teraz orientovať na iné funkcionlity eshopu.

              //     <br/>
              //     <br/>
              //     Jozef Peklanský
              //   </p>
              // </Grid>
              // </Div>,
          ]
            }
          </Carousel>
    </Style>
  );
}

const Style = css("div")({
    "@media only screen and (max-width: 1000px)": {
      ".fuk-grid": {
        display: "block",
        "img": {
          maxWidth: "150px",
        },
        "p": {
          padding: "5px !important",
          marginLeft: "0 !important",
        },

      },
    },
    maxWidth: "1200px",
    margin: "80px auto",
    paddingBottom: "80px",

    "img": {
      maxWidth: "200px",
      clipPath: "circle(99px at center)",

    },

    "p": {
      textAlign: "justify",
      fontSize: "1.4em",
      fontStyle: "italic",
    },

})

export default References;
