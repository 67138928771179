import ReactGA from "react-ga";

 /**
 * Initialize Google Analytics
 */
  export const initGA = (trackingID) => { 
    console.info('Initialize Google Analytics: ' + trackingID)          
    ReactGA.initialize(trackingID); 
 }

 /**
 * Add custom tracking event.
 */
export const eventGA = (category: string, action: string, label: string) => {
    console.info('Custom Google Analytics event: ' + label)          
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
};
